import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

type Props = {
  open: boolean;
  title: string;
  action_id: string;
  params: string[];
  runAction: (
    action_id: string,
    query_params: { [k: string]: unknown }
  ) => void;
  cancelAction: (action_id: string) => void;
};

function QueryParamsWindow({
  open,
  title,
  action_id,
  params,
  runAction,
  cancelAction: cancelAction,
}: Props) {
  const [queryParams, setQueryParams] = useState<Map<string, string>>(
    new Map()
  );
  const updateStatus = (name: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newQueryParams = new Map(queryParams);
      newQueryParams.set(name, e.currentTarget.value);
      setQueryParams(newQueryParams);
    };
  };
  const runParamsActionHandler = (e: React.FormEvent) => {
    e.preventDefault();
    runAction(action_id, Object.fromEntries(queryParams));
  }

  return (
    <Drawer open={!!open} onClose={()=>cancelAction(action_id)}>
      <Box padding={3}>
        <Typography marginBottom={2}>Внесите параметры для запроса</Typography>
        <form action="#" onSubmit={runParamsActionHandler}>
        {params.map((param, index) => (
          <Box width={"100%"}>
            <TextField
              key={param}
              label={param}
              variant="outlined"
              onChange={updateStatus(param)}
              value={queryParams.get(param) || ""}
              size="small"
              fullWidth={true}
              sx={{ marginBottom: 2 }}
              />
          </Box>
        ))}
        <Box width={"100%"}>
          <ButtonGroup fullWidth={true} size="small">
            <Button
              onClick={runParamsActionHandler}
              type="submit"
              >
              OK
            </Button>
            <Button onClick={() => cancelAction(action_id)}>Cancel</Button>
          </ButtonGroup>
        </Box>
      </form>
      </Box>
    </Drawer>
  );
}

export default QueryParamsWindow;
