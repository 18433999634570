import React from "react";
import { QuickAction } from "./types";
import ImageOrNumber from "./ImageOrNumber";
import { Tooltip } from "@mui/material";
import "./QuickActionsPanel.css";

type Props = { 
  items: QuickAction[]; 
  runAction: (action_id: string, name: string, type: string) => void; 
};

function QuickActionsPanel({ items, runAction }: Props) {
  return (
    <div className="quickActionPanel">
      {items.map((item, indx) => (
        <Tooltip key={item.action_id} title={item.name} arrow>
          <button
            role="button"
            onClick={() => runAction(item.action_id, item.name, item.type)}
            className="quickActionButton"
          >
            <ImageOrNumber
              src={`static/img/${item.icon_path.split("/").pop()}`}
              index={indx}
            />
          </button>
        </Tooltip>
      ))}
    </div>
  );
}

export default QuickActionsPanel;
