import G6, {Graph, IG6GraphEvent} from "@antv/g6";
import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Item, Menu, Separator, Submenu, useContextMenu} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import {JrpcClient} from "../../jrpc/frontend/0_0_1/types";
import {JrpcController} from "../../jrpc/controller";
import {JrpcServer} from "../../jrpc/server/0_0_1/types";
import ReactDOM from "react-dom";
import Header from "../header/Header";
import Modal from "../node_window/modal";
import Notification from "../notification_window/notification";
import {Error} from "@mui/icons-material";
import TextPasteInputWindow from "../text_paste_input/TextPasteInputWindow";
import {Box} from "@mui/material";
import {FilesPasteWindow} from "../files_paste_window/FilesPasteWindow";
import Settings from "../../settings";
import SaveGraphWindow from "../save_graph_window/SaveGraphWindow";
import {useSearchParams} from "react-router-dom";
import AccountActionsButton from "../login/AccountActionsButton";
import Typography from "@mui/material/Typography";
import OpenGraphWindow from "../open_graph_window/OpenGraphWindow";
import "./graph-plot.css"
import QuickActionsPanel from "../quickActionsPanel/QuickActionsPanel";
import { QuickAction } from "../quickActionsPanel/types";
import QueryParamsWindow from "../queryParamsWindow/queryParamsWindow";
import { INodeCardData, INodeData } from "../common/types/nodeData";

const MENU_ID = 'cm'

const eventsList = [];

declare type CtxMenuCallableAction = { id: string }
declare type CtxMenuRpcAction = { method_name: string, params: { [p: string]: unknown } }
declare type Action = { type: string, data: CtxMenuCallableAction | CtxMenuRpcAction }
declare type queryParamsWindow = {title: string, params: string[], action_id: string}

const fileAcceptTypes = "image/png, image/jpeg, image/tiff, image/bmp, application/pdf, application/rtf, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword";
const tableFilesAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv";

function GraphPlot(
    props: {
        jrpcController: JrpcController,
        jrpcServerClient: JrpcServer,
        notificationInfo: { open: boolean, text: string, code: number },
        changeNotificationState: (props: { open: boolean, text: string, code: number }) => void
    }
) {
    const [query, setQuery] = useSearchParams()
    const {jrpcController, jrpcServerClient, changeNotificationState, notificationInfo} = props
    const [contextMenuData, setContextMenuData] = useState({children: []})
    const {show, hideAll} = useContextMenu({id: MENU_ID});
    const [isDesktopCtx, setIsDesktopCtx] = useState(false);
    const [rightClickPosition, setRightClickPosition] = useState([0, 0])
    const [userGraphs, setUserGraphs] = useState([]);
    const [quickActions, setQuickActions] = useState<QuickAction[]>([]);
    let fingerTouchTimeout: string | number | NodeJS.Timeout | undefined = undefined;
    let touchStartPosition = [0, 0];
    let touchMovingPosition = [0, 0];
    let touchStartTimestamp = 0;
    let movedOnTouch = false;
    const graphRef = React.useRef(null);
    // @ts-ignore
    const [openedNodes, setOpenedNodes] = useState<INodeData[]>([{type: "", data: {}, meta: {}, name: "", grouped: false, inners: [], sources_names: []}]);
    const [selectedNode, setSelectedNode] = useState<number>(0);
    const [modalNodeData, setModalActive] = useState<INodeCardData>(
        {
            visible: false,
            isGroup: false,
            nodeName: '',
            nodesList: ['', ''],
            nodeInfo: {}
        }
    );
    const [saveGraphWindowActive, setSaveGraphWindowActive] = useState(false);
    const [textPasteWindowActive, setTextPasteWindowActive] = useState(false);
    const [openGraphWindowActive, setOpenGraphWindowActive] = useState(false);
    const [filePasteWindowData, setFilePasteWindowData] = useState({active: false, accept: "", onSend: (files: string[]) => {}});
    const [queryParamsWindow, setQueryParamsWindow] = useState<queryParamsWindow | null>(null);

    let graph: Graph | null = null;
    const [ctxMenuEvent, setCtxMenuEvent] = useState();

    const addOpenedNode = (nodeData: INodeData) => {
        let newSelectedIndex = -1;
        setOpenedNodes(openedNodes => {
            if (openedNodes.find(node => node.name === nodeData.name)) {
                newSelectedIndex = openedNodes.findIndex(node => node.name === nodeData.name);
                setSelectedNode(newSelectedIndex);
                return openedNodes;
            } else {
                newSelectedIndex = openedNodes.length;
                setSelectedNode(newSelectedIndex);
                return [...openedNodes, nodeData];
            }
        })
        selectNodeByName(nodeData.name);
    }
    
    const removeOpenedNode = (index: number, currentIndex: number) => {
        setOpenedNodes((nodes)=>{
            const newNodes = [...nodes.filter((node, indx)=> indx !== index)]
            if (currentIndex > index) {
                setSelectedNode(ind => {
                    const newIndex = ind-1
                    const nodeData = newNodes[newIndex];
                    setModalActive({
                        visible: true,
                        isGroup: nodeData.grouped,
                        nodeName: nodeData.name,
                        nodesList: Object.keys(nodeData.inners),
                        //@ts-ignore
                        nodeInfo: nodeData.data
                });
                return newIndex
                })}
            else if (nodes.length == 2) {
                setSelectedNode(()=>{
                    const newIndex = 0;
                    const nodeData = newNodes[newIndex];
                    setModalActive({
                        visible: true,
                        isGroup: nodeData.grouped,
                        nodeName: nodeData.name,
                        nodesList: Object.keys(nodeData.inners),
                        //@ts-ignore
                        nodeInfo: nodeData.data
                });
                return newIndex
                });
            }
            else if (currentIndex === index && index === nodes.length - 1) {
                setSelectedNode(ind =>{
                    const newIndex = ind-1
                    const nodeData = newNodes[newIndex];
                    setModalActive({
                        visible: true,
                        isGroup: nodeData.grouped,
                        nodeName: nodeData.name,
                        nodesList: Object.keys(nodeData.inners),
                        //@ts-ignore
                        nodeInfo: nodeData.data
                });
                return newIndex
                });
            }
            return newNodes;
        })
    }

    const DeleteSelectedNodes = (event: any) => {
        const selectedNodes = graph?.findAllByState('node', 'selected');
        if (event.key === 'Delete') {
            selectedNodes?.forEach(node => {
                const nodeId = node.getModel().id;
                if (nodeId){
                    jrpcServerClient.removeNode({node_name: nodeId});
                }
            });
        }
    };

    const runQuickMenuAction = (action_id: string, name: string, type: string) => {
        const selectedNodes = window.graph?.findAllByState('node', 'selected');
        const selectedNodesNames = selectedNodes?.map(node => node.getModel().id).filter(id => Boolean(id));
        // @ts-ignore
        jrpcServerClient.callQuickMenuAction({action_id: action_id, name: name, type: type, selected_nodes: [...selectedNodesNames]})

    }

    const cancelQuickFunctionWithParams = (action_id: string)=>{
        jrpcServerClient.cancelQuickFuncWithParams({action_id})
        setQueryParamsWindow(null)
    }

    const runQuickFuncWithPrams = (action_id: string, query_params: {[k:string]: unknown}) => {
        jrpcServerClient.runQuickFuncWithParams({action_id, query_params});
        setQueryParamsWindow(null)
    }

    const SaveGraph = (graph: any) => {
        jrpcServerClient.saveThisGraph()
    }

    const SaveGraphAs = (graph: any) => {
        setSaveGraphWindowActive(true)
    }


    const SaveGraphToExcel = () => {
        jrpcServerClient.saveGraphToExcelCalled({})
    }

    const OpenGraph = (graph: any) => {
        jrpcServerClient.showUserGraphsWindowCalled()
    }

    const ClearGraph = (graph: any) => {
        jrpcServerClient.removeThisGraph()
    }

    const SelectAllNodes = (graph: any) => {
        const nodes = graph.getNodes();  // Get all nodes in the graph
        nodes.forEach((node: any) => {
            graph.setItemState(node, 'selected', true); // Set the state of each node to 'selected'
        });
    }

    const selectNodeByName = (name: string) => {
        const graph = window.graph;
        const nodes = graph.getNodes();
        nodes.forEach((node: any) => {
            console.log(node.getModel().id)
            if (node.getModel().id === name){
                graph.setItemState(node, 'selected', true); // Set the state of each node to 'selected'
            }
            else {
                graph.setItemState(node, 'selected', false);
            }
        });
    }

    const handleNodeSelectChange = (id: number) => {
        const nodeData = openedNodes[id];
        setModalActive({
            visible: true,
            isGroup: nodeData.grouped,
            nodeName: nodeData.name,
            nodesList: Object.keys(nodeData.inners),
            //@ts-ignore
            nodeInfo: nodeData.data
        });
        setSelectedNode(id);
        selectNodeByName(nodeData.name);
    }

    const DeselectAllNodes = (graph: any) => {
        const nodes = graph?.getNodes(); // Get all nodes in the graph
        nodes.forEach((node: any) => {
            graph?.setItemState(node, 'selected', false); // Set the state of each node to 'selected'
        });
    }

    const SelectAllNeighbors = (graph: any) => {
        const nodes = graph.findAllByState('node', 'selected'); // Get all nodes in the graph
        nodes.forEach((node: any) => {
            const neighbors = graph?.getNeighbors(node); // Get the neighbors of each node
            neighbors.forEach((neighbor: any) => {
                graph.setItemState(neighbor, 'selected', true); // Set the state of each neighbor to 'selected'
            });
        });
    }

    function hideAllNodes(graph: any) {
        const nodes = graph?.getNodes(); // Get all nodes in the graph
        nodes.forEach((node: any) => {
            graph.hideItem(node); // Hide each node
        });
    }

    function hideSelectedNodes(graph: any) {
        const nodes = graph.findAllByState('node', 'selected'); // Get all nodes in the graph
        nodes.forEach((node: any) => {
            const nodeId = node.getModel().id;
            graph.hideItem(nodeId); // Hide each selected node
        })
    }

    function showAllHiddenNodes(graph: any) {
        const hiddenNodes = graph?.getNodes(); // Get all hidden nodes in the graph
        hiddenNodes.forEach((node: any) => {
            graph.showItem(node); // Show each hidden node
        });
    }

    const headerFunction = React.useMemo(() => [
        {
            title: 'Сохранить',
            function: () => SaveGraph(graph),
        },
        {
            title: 'Сохранить как',
            function: () => SaveGraphAs(graph),
        },
        {
            title: 'Сохранить в Excel',
            function: () => SaveGraphToExcel(),
        },
        {
            title: 'Открыть',
            function: () => OpenGraph(graph),
        },
        {
            title: 'Удалить',
            function: () => ClearGraph(graph),
        },
        {
            title: 'Выделить все',
            function: () => SelectAllNodes(graph),
        },
        {
            title: 'Скрыть все',
            function: () => hideAllNodes(graph),
        },
        {
            title: 'Скрыть выделенное',
            function: () => hideSelectedNodes(graph),
        },
        {
            title: 'Отобразить все',
            function: () => showAllHiddenNodes(graph),
        },
        {
            title: 'Выделить связанные объекты',
            function: () => SelectAllNeighbors(graph),
        },
    ], [graph]);

    
    const contextMenu = new G6.Menu({
        getContent(evt) {
            // @ts-ignore
            showContextMenu(evt);
            return ``
        },
        visible: false,
        itemTypes: ['node', 'canvas']
    })


    useEffect(() => {
        const graphName = query.get("graphName");
        if (graphName) {
            jrpcServerClient.openGraph({name: graphName})
        }
        if (graph === null) {
            graph = new G6.Graph(
                {
                    height: window.innerHeight,
                    width: window.innerWidth,
                    // @ts-ignore
                    container: ReactDOM.findDOMNode(graphRef.current),
                    modes: {
                        default: [
                            'drag-canvas',
                            {
                                type: 'zoom-canvas',
                                maxZoom: 2,
                                minZoom: 0.1
                            },
                            'drag-node',
                            'click-select',
                            'brush-select'
                        ],
                    },
                    defaultNode: {
                        style: {
                            cursor: 'grab'
                        },
                    },
                    nodeStateStyles: {
                        selected: {
                            fill: 'lightsteelblue', // Change fill color when selected
                            stroke: 'blue', // Change stroke color when selected
                            fillOpacity: 0.4, // Set fill opacity when selected
                            strokeOpacity: 1, // Set stroke opacity when selected
                            lineWidth: 3, // Set line width when selected
                            shadowColor: 'rgba(0, 0, 0, 0.3)', // Add shadow when selected
                            shadowBlur: 5, // Set shadow blur when selected
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease-in-out',
                        },
                        hover: {
                            transition: 'transform 0.3s ease-in-out',
                            fill: 'lightsteelblue', // Change fill color when hovered
                            stroke: 'blue',
                            fillOpacity: 0.3, // Set fill opacity to make it transparent
                            strokeOpacity: 0.5, // Change stroke color when hovered
                            lineWidth: 2
                        },
                    },
                    plugins: [
                        contextMenu,
                        // tooltip
                    ],
                }
            )
        }

        graph.data({nodes: [], edges: []})
        graph.render();
        graph.moveTo(graph.getWidth() / 2, graph.getHeight() / 2)
        graph.zoom(0.75)
        graph.on("touchstart", e => {
            movedOnTouch = false;
            if (e.item?._cfg?.type === "node") {
                graph?.setItemState(e.item, "selected", true);
                const diff = Date.now() - touchStartTimestamp;
                if (diff < 50) return;
                const isDblClick = diff < 500;
                touchStartTimestamp = Date.now();
                if (isDblClick) {
                    handleNodeDblClick(e)
                    return
                }
            } else {
                DeselectAllNodes(graph);
            }
            touchStartPosition = [e.clientX, e.clientY];
            showCtxMenuOnFingerTouch(e)
        })
        graph.on("touchend", e => {
            clearTimeout(fingerTouchTimeout)
        })
        graph.on("touchmove", e => {
            touchMovingPosition = [e.clientX, e.clientY]
            movedOnTouch = true;
        })


        class Visualizer implements JrpcClient {
            _serverClient: JrpcServer | undefined = undefined;

            setServerClient(serverClient: JrpcServer) {
                this._serverClient = serverClient
            }

            pasteFromClipboard(): Promise<string> {
                // @ts-ignore
                throw new Error("Method not implemented.");
            }

            formatEdge(props: {
                first_node_name: string;
                second_node_name: string;
                text?: string | undefined;
                color?: number[] | undefined;
                width?: number | undefined;
            }): void {
                // @ts-ignore
                throw new Error("Method not implemented.");
            }

            hideNode(props: { node_name: string; }): void {
                const {node_name} = props;
                graph?.removeItem(node_name);
            }

            async getViewCenterPosition(): Promise<number[]> {
                const point = graph?.getGraphCenterPoint();
                return [point?.x || 0, point?.y || 0];
            }

            showMessageBox(props: {
                title: string;
                text: string;
                button_yes: string;
                button_no: string;
                button_action: string;
            }): Promise<string> {
                // @ts-ignore
                throw new Error("Method not implemented.");
            }

            setStatus(props: { text: string; code?: number | undefined; }): void {
                const {text, code = 0} = props;

                console.log(`Status: ${text} code: ${code}`);
                changeNotificationState(
                    {
                        open: true,
                        text: text,
                        code: code
                    }
                );
            }

            clearStatus(): void {
                changeNotificationState({open: false, text: '', code: 0})
            }

            changeNodeOpacity(props: { node_name: string; opacity?: null | number | undefined; }): void {
                const {node_name, opacity} = props;

                // try {
                //     graph?.update(node_name, {style: {opacity: typeof opacity === 'number' ? opacity : 1}})
                // } catch { /* empty */}
            }


            showEdge(props: { first_node_name: string, second_node_name: string, text: string }): void {
                const {first_node_name, second_node_name, text} = props
                graph?.addItem(
                    'edge',
                    {source: first_node_name, target: second_node_name, label: text}
                );
            }


            showNode(props: {
                node_name: string,
                position?: number[],
                has_icon?: boolean,
                node_meta_data?: { [p: string]: unknown }
            }): void {
                const {node_name, position, has_icon, node_meta_data} = props
                const [x, y] = position || [0, 0]
                const img = has_icon ? `/static/img/` + node_meta_data?.type + '.png' : ''
                const node_size = [100, 100]
                graph?.addItem('node', {
                    id: node_name,
                    type: 'image',
                    x, y,
                    img: img,
                    label: node_name,
                    labelCfg: {position: 'bottom', style: {fontSize: 16}},
                    size: node_size,
                    clipCfg: {
                        show: true,
                        type: 'circle',
                        r: node_size[0] / 2
                    }
                })
            }

            showCtxMenu(props: { data: { [p: string]: unknown }, position: number[] }): void {
                const {data, position} = props
                // @ts-ignore
                setContextMenuData(data)
            }

            changeNodeSize(props: { node_name: string; size: number }): void {
                const item = graph?.findById(props.node_name);
                // item?.update(
                //     {
                //         size: [props.size, props.size],
                //         clipCfg: {
                //             show: true,
                //             type: 'circle',
                //             r: props.size / 2,
                //             labelCfg: {position: 'bottom'},
                //         },
                //     })
                // item?.refresh()
            }

            downloadTmpFile(props: { id: string }): void {
                const {id} = props;
                console.log(`Download tmp file ${id}`);
                const link = document.createElement('a');
                link.href = `${Settings.getApiHost()}/api/v1/files/graph/${id}/`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            moveNode(props: { node_name: string; position: number[] }): void {
                const {node_name, position} = props;
                const node = graph?.find("node", n => n.get("id") === node_name)
                if (node) graph?.updateItem(node, {x: position[0], y: position[1]})
            }

            setGraphName(props: { name: string }): void {
                setSaveGraphWindowActive(false)
                if (props.name === null) {
                    query.delete('graphName');
                } else {
                    query.set('graphName', props.name);
                }
                setQuery(query)
            }

            showUserGraphsWindow({graphs}: { graphs: string[] }): void {
                setOpenGraphWindowActive(true)
                // @ts-ignore
                setUserGraphs(graphs)
            }

            pushQuickPanel(props: { items: QuickAction[]; }): void {
                setQuickActions(props.items || []);
            }

            pushQueryParamsWindow(props: {title: string, params: string[], action_id: string}): void {
                setQueryParamsWindow(props)
            }

            confirmRemoveNode(props: { node_name: string; }): void {
                graph?.removeItem(props.node_name); // Remove the node from the graph
            }
            
            //@ts-ignore
            showNodeCard(props: { node_data: INodeData }): void {
                const nodeData = {...props.node_data};
                addOpenedNode(nodeData);
                setModalActive({
                    visible: true,
                    isGroup: nodeData.grouped,
                    nodeName: nodeData.name,
                    nodesList: Object.keys(nodeData.inners),
                    //@ts-ignore
                    nodeInfo: nodeData.data
                });
            }
        }

        const visualizer = new Visualizer()
        visualizer.setServerClient(jrpcServerClient)
        jrpcController.setVisualizer(visualizer)

        const handleNodeDblClick = async (e: any) => {
            const Node: INodeData = await jrpcServerClient.getNodeInfo({node_name: e.item._cfg.id});
            addOpenedNode(Node);
            setModalActive({
                visible: true,
                isGroup: Node.grouped,
                nodeName: Node.name,
                nodesList: Object.keys(Node.inners),
                //@ts-ignore
                nodeInfo: Node.data
            });
        };

        graph?.on('node:dblclick', handleNodeDblClick);
        window.graph = graph;
        window.addEventListener('keydown', DeleteSelectedNodes);

        return () => {
            window.removeEventListener('keydown', DeleteSelectedNodes);
            graph?.off('node:dblclick', handleNodeDblClick);
        };

    }, []);

    useEffect(() => {
        if (!ctxMenuEvent || !contextMenuData.children) return;
        // @ts-ignore
        show({event: ctxMenuEvent, id: MENU_ID});
    }, [contextMenuData]);

    const handleCtxMenuItemClick = (
        action: Action | undefined
    ) => {
        if (!action) return
        if (action.type === 'rpc') {
            // @ts-ignore
            jrpcServerClient.doRpc(action.data.method_name, action.data.params, false)
        } else if (action.type === 'callable') {
            action.data = action.data as CtxMenuCallableAction;
            jrpcServerClient.callContextMenuItem({cm_item_id: action.data.id})
        }
    }

    function buildItem(item: { type: string, text: string, action?: Action, children: [], enabled: boolean }) {
        const {type, text, action, children, enabled} = item;
        if (item.type === 'header') {
            return <Item key={item.text}>{item.text}</Item>
        } else if (item.type === 'separator') {
            return <Separator key={uuidv4()}/>
        } else if (item.type === 'action' && item.text) {
            return <Item key={item.text} onClick={() => handleCtxMenuItemClick(item.action)} disabled={!enabled}>{item.text}</Item>
        } else if (item?.children?.length > 0) { // problem with open context menu on CSV object
            const SubmenuChildrenWithClickScroll = () => {
                if (item.children.length < 7) return <Box>{item.children.map(item => buildItem(item))}</Box>
                const Children = () => (
                    <Box className={"submenuBox"} display={"grid"} alignItems={"top"} sx={{overflowY:"scroll"}}>
                        {
                            item.children.map(item => buildItem(item))
                        }
                    </Box>
                );
                return <Children/>
            }
            // @ts-ignore
            return <Submenu key={uuidv4()} label={item.text} children={<SubmenuChildrenWithClickScroll/>}/>
        }
    }

    async function showCtxMenuOnFingerTouch(e: IG6GraphEvent) {
        hideAll()
        clearTimeout(fingerTouchTimeout);
        fingerTouchTimeout = setTimeout(
            () => showContextMenu(e),
            400
        );
    }

    async function showContextMenu(e: IG6GraphEvent) {
        if (
            movedOnTouch &&
            Math.pow(
                Math.pow(touchMovingPosition[0] - touchStartPosition[0], 2)
                +
                Math.pow(touchMovingPosition[1] - touchStartPosition[1], 2),
                0.5
            ) > 10
        ) return;
        // @ts-ignore
        setCtxMenuEvent(e);
        const position = [Math.floor(e.x), Math.floor(e.y)];
        setRightClickPosition(position)
        // @ts-ignore
        switch (e.item?._cfg.type) {
            case undefined:
                setIsDesktopCtx(true)
                jrpcServerClient.pushDesktopCtxMenu({position})
                break;
            case 'node':
                setIsDesktopCtx(false)
                // @ts-ignore
                jrpcServerClient.pushNodeCtxMenu({node_name: e.item._cfg.model.label, position})
                break;
        }
    }

    const handleFilesSend = (files: string[]) => {
        jrpcServerClient.addTempFilesOnScheme({files, position: rightClickPosition})
    }

    const handleTextPaste = (text: string) => {
        jrpcServerClient.addTextOnScheme({text, position: rightClickPosition})
    }

    const handleTablesSend = (tables: string[]) => {
        jrpcServerClient.addTableFileOnScheme({files: tables, position: rightClickPosition})
    }

    // @ts-ignore
    return (
        <>
            <Header additionalFunctions={headerFunction}>
                <Typography>{query.get("graphName") === null ? '' : `Схема: ${query.get("graphName")}`}</Typography>
                <AccountActionsButton/>
            </Header>
            <QuickActionsPanel items={quickActions} runAction={runQuickMenuAction}/>
            <Box>
                <SaveGraphWindow
                    active={saveGraphWindowActive}
                    setActive={setSaveGraphWindowActive}
                    saveHandler={jrpcServerClient.saveGraphAs}
                    initName={query.get("graphName") === null ? '' : query.get("graphName") as string}
                />
                <TextPasteInputWindow
                    active={textPasteWindowActive}
                    setActive={setTextPasteWindowActive}
                    sendHandler={handleTextPaste}
                />
                <FilesPasteWindow
                    onSend={filePasteWindowData.onSend}
                    active={filePasteWindowData.active}
                    setActive={(active: boolean) => setFilePasteWindowData(data => {
                        return {...data, active}
                    })}
                    accept={filePasteWindowData.accept}
                />
                <OpenGraphWindow
                    active={openGraphWindowActive}
                    setActive={setOpenGraphWindowActive}
                    // @ts-ignore
                    graphs={userGraphs}
                    onGraphDelete={(g: string)=>jrpcServerClient.removeGraph({name: g})}
                />
                <QueryParamsWindow 
                open={!!queryParamsWindow}
                title={queryParamsWindow?.title||""} 
                action_id={queryParamsWindow?.action_id||""} 
                params={queryParamsWindow?.params|| []} 
                runAction={runQuickFuncWithPrams} 
                cancelAction={cancelQuickFunctionWithParams}
                />
                <Menu
                    id={MENU_ID}
                    className="contextify-menu"
                >
                    {contextMenuData?.children?.map(item => buildItem(item))}
                    {
                        isDesktopCtx && <>
                            <Submenu label={"Добавить на схему..."} style={{overflow: "auto"}}>
                                <Item onClick={() => setTextPasteWindowActive(true)}>Текст</Item>
                                <Item
                                    onClick={
                                        () => setFilePasteWindowData(
                                            {
                                                active: true,
                                                accept: fileAcceptTypes,
                                                onSend: handleFilesSend
                                            }
                                        )
                                    }
                                >
                                    Файлы
                                </Item>
                                <Item
                                    onClick={
                                        () => setFilePasteWindowData(
                                            {
                                                active: true,
                                                accept: tableFilesAccept,
                                                onSend: handleTablesSend
                                            }
                                        )
                                    }
                                >
                                    Данные из таблиц
                                </Item>
                            </Submenu>
                        </>
                    }
                </Menu>
                <div style={{display: "flex"}}>
                    <Modal 
                    modalNodeData={modalNodeData} 
                    setActive={setModalActive} 
                    jRPCServer={jrpcServerClient} 
                    openedNodes={openedNodes} 
                    selectedNode={selectedNode} 
                    setSelectedNode={setSelectedNode} 
                    removeNode={removeOpenedNode}
                    onSelectChange={handleNodeSelectChange}/>
                    <div ref={graphRef}/>
                </div>
            </Box>
            <div>
                <Notification info={notificationInfo} changeNotificationState={changeNotificationState}/>
            </div>
        </>

    )
}

export default GraphPlot;
