import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DataGrid from '../table/data_grid';
import "./nodes_list.css";
import {JrpcServer} from "../../jrpc/server/0_0_1/types";

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomNumber = Math.random().toString(36).substr(2);
    return timestamp + randomNumber;
};

const createTabelRows = (data: object): any[] => {
    return Object.entries(data).map(([name, data]) => ({id: generateUniqueId(), name, data }));
};


export default function SelectedListItem(props: { data?: any, jRPCServer?: JrpcServer }) {
    const { data, jRPCServer } = props;
    const [rows, setRows] = useState<any[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0); // Start selectedIndex from 0
    const isMobile = window.innerWidth < 600;
    const windowWidth = window.innerWidth;

    const fetchData = async () => {
        if (props.data?.nodesList) {
            console.log(props.data.nodesList[selectedIndex]);
            const node = await jRPCServer?.getNodeInfo({ node_name: props.data.nodesList[selectedIndex] });
            if (!node) return;
            const newRows: any = createTabelRows(node.data);
            setRows(newRows);
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.data, props.jRPCServer, selectedIndex]);

    const handleListItemClick = (event: any, index: number) => {
        setSelectedIndex(index);
    };

    const additionalColumns = [
        { 
            field: 'name', 
            headerName: 'Наименование', 
            editable: true,
            width: isMobile? windowWidth*.3-8:150,
        },
        {
          field: 'data',
          headerName: 'Данные',
          editable: true,
          width: isMobile? windowWidth*.7-8:400,
        },
    ];

    return (
        <Box>
            <div className="shared_container">
                <div className="container data-list">
                    <List component="nav" aria-label="secondary mailbox folder">
                        {props.data?.nodesList?.map((item: any, index: any) => (
                            <ListItemButton
                                key={item.toString()}
                                selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}
                            >
                                <ListItemText primary={item}  style={{textOverflow: 'ellipsis'}}/>
                            </ListItemButton>
                        ))}
                    </List>
                </div>
                <div className="container data-grid">
                    <DataGrid
                        rows={rows}
                        setRows={setRows}
                        additionalColumns={additionalColumns}
                        onRemoveNodeAttr={(attrName)=>jRPCServer?.removeNodeAttr({ node_name: props.data.nodesList[selectedIndex], attr_name: attrName})}
                        onSetNodeAttr={(attrName, attrValue)=>jRPCServer?.setNodeAttr({ node_name: props.data.nodesList[selectedIndex], attr_name: attrName, attr_value: attrValue})}
                    />
                </div>
            </div>
        </Box>
    );
}
