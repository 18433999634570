import axios from "axios";
import TokenService from "./token.service";
import {LOGIN_URL, REFRESH_TOKEN_URL} from "../../constants";
import Settings from "../../settings";

const api = axios.create({
  baseURL: Settings.getApiHost(),
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== LOGIN_URL && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await api.post(
              REFRESH_TOKEN_URL,
              {refresh: TokenService.getLocalRefreshToken()}
          );

          const { access, refresh } = rs.data;

          TokenService.updateLocalTokenPair(access, refresh);

          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default api;