import * as React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {JrpcServer} from "../../jrpc/server/0_0_1/types";


const addAditionalColumns = (columns: any[], aditionalColumns: any[]) => {
  // Merge the columns arrays using the spread operator
  const mergedColumns = [...aditionalColumns, ...columns];
  return mergedColumns;
};


export default function FullFeaturedCrudGrid(
    props: {
      rows: any,
      setRows: any
      additionalColumns: any,
      onSetNodeAttr?: (attrName: string, attrValue: string)=>void,
      onRemoveNodeAttr?: (attrName: string)=>void
    }
) {
  const { rows, setRows } = props;
  const [rowModesModel, setRowModesModel]: [{[key: string]: any}, any] = React.useState({});
  const isMobile = window.innerWidth < 600;

  
  const addNewRow = () => {
    const id = Math.random()*100+100;
    setRows((oldRows: any) => [...oldRows, {id, name: '', data: '', isNew: true }]);
  };

  const handleRowEditStop = (params: any, event: any) => {
    // if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    //   event.defaultMuiPrevented = true;
    // }
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: string) => () => {
    // @ts-ignore
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: string) => () => {
    // @ts-ignore
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: string) => () => {
    // @ts-ignore
    rows.forEach(row=>row.id===id && props.onRemoveNodeAttr(row.name));
    setRows(rows.filter((row: any) => row.id !== id));
  };

  const handleCancelClick = (id: string) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row: any) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: {[key: string]: any}) => {
    const updatedRow = { ...newRow, isNew: false };

    // @ts-ignore
    setRows(rows=>rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    props.onSetNodeAttr && props.onSetNodeAttr(newRow.name, newRow.data);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: {[key: string]: any}) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действие',
      cellClassName: 'actions',
      // @ts-ignore
      getActions: ({ id }: {id: string}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // const mergedColumns = isMobile? props.aditionalColumns: addAditionalColumns(columns, props.aditionalColumns);
  const mergedColumns = props.additionalColumns;

  return (
      <Box sx={{padding:1, maxHeight: "85vh"}}>
        <div id="d-grid"> 
          <DataGrid
            rows={rows}
            // @ts-ignore
            columns={mergedColumns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            autoHeight
            slotProps={{
              toolbar: {setRows, setRowModesModel},
            }}
            getRowHeight={() => 'auto'}
            hideFooter
          />
        </div>
        <Stack direction="row" spacing={1}>
          <Button color="primary" startIcon={<AddIcon />} onClick={addNewRow}>
            Добавить
          </Button>
        </Stack>
      </Box>

  );
}
