import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";

type Props = { src: string; index: number };

function ImageOrNumber({ src, index }: Props) {
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setComponent(
        <img
          src={src}
          width="25px"
          height="25px"
          alt=""
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        />
      );
    };
    img.onerror = () => {
      setComponent(
        <span
          className="quickActionButtonText"
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        >
          {index}
        </span>
      );
    };
  }, []);
  return <>{component}</>;
}

export default ImageOrNumber;
